import { Button, type ButtonProps } from "@chakra-ui/react";
import { useFormikContext } from "formik";

export type SubmitButtonProps = Omit<ButtonProps, "type">;

export function SubmitButton({
  children,
  isLoading,
  ...props
}: SubmitButtonProps) {
  const { isSubmitting } = useFormikContext();
  return (
    <Button
      type="submit"
      isLoading={isSubmitting || isLoading}
      loadingText="Saving..."
      {...props}
    >
      {children ?? "Save"}
    </Button>
  );
}
