import {
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  type InputProps,
  InputRightElement,
  useDisclosure,
  InputLeftAddon,
  InputRightAddon,
} from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { FiRefreshCw } from "react-icons/fi";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { FormControl, type FormControlProps } from "../control";
import { randomString } from "@nest/common/util";

export type FormInputProps = Omit<FormControlProps, "children"> &
  Pick<
    InputProps,
    "type" | "autoComplete" | "placeholder" | "size" | "onFocus" | "onBlur"
  > & {
    leftElement?: React.ReactNode;
    rightElement?: React.ReactNode;
    leftAddon?: React.ReactNode;
    rightAddon?: React.ReactNode;
    children?: React.ReactNode;
    showPassword?: boolean;
    showPasswordGenerator?: boolean;
  };

function PassordGeneratorButton({ name }: { name: string }) {
  const { setFieldValue } = useFormikContext();
  return (
    <Button
      leftIcon={<FiRefreshCw />}
      variant="secondary"
      onClick={() =>
        setFieldValue(name, randomString({ length: 16, type: "alphanumeric" }))
      }
    >
      Generate
    </Button>
  );
}

export function FormInput({
  isRequired,
  isDisabled,
  name,
  id = name,
  label,
  helperText,
  leftElement,
  rightElement,
  leftAddon,
  rightAddon,
  children,
  showPassword = false,
  showPasswordGenerator = false,
  onBlur: onBlurProp,
  ...props
}: FormInputProps) {
  const [field, meta, helpers] = useField({
    name,
    type: props.type,
  });

  const { isOpen: isPasswordVisible, onToggle: onTogglePassword } =
    useDisclosure({
      defaultIsOpen: showPassword,
    });

  const [textValue, setTextValue] = useState<string>(
    (typeof field.value === "number" ? field.value.toString() : field.value) ||
      ""
  );

  useEffect(() => {
    setTextValue((prevTextValue) => {
      if (props.type === "number") {
        if (field.value !== Number.parseFloat(prevTextValue)) {
          if (field.value === null) {
            return "";
          } else {
            return field.value.toString();
          }
        } else {
          return prevTextValue;
        }
      } else {
        if (field.value === null) {
          return "";
        } else {
          return field.value;
        }
      }
    });
  }, [field.value, props.type]);

  if (props.type === "password" && !rightElement) {
    rightElement = (
      <IconButton
        tabIndex={-1}
        variant="link"
        aria-label={isPasswordVisible ? "Mask password" : "Reveal password"}
        icon={isPasswordVisible ? <HiEyeOff /> : <HiEye />}
        onClick={() => onTogglePassword()}
      />
    );
  }

  const input = (
    <Input
      {...props}
      id={id}
      type={
        props.type === "password" && isPasswordVisible ? "text" : props.type
      }
      {...field}
      value={textValue}
      onChange={(event) => {
        let value: number | string | null = event.target.value;

        setTextValue(value);

        if (
          !isRequired &&
          (value === "" || value === null || value === undefined)
        ) {
          value = null;
        }

        if (value !== null && props.type === "number") {
          const numValue = Number.parseFloat(value);
          if (!Number.isNaN(numValue)) {
            value = numValue;
          }
        }

        helpers.setValue(value);
      }}
      onBlur={(event) => {
        field.onBlur(event);
        onBlurProp?.(event);
      }}
    />
  );

  let inner =
    leftElement || rightElement || leftAddon || rightAddon || children ? (
      <InputGroup>
        {input}
        {leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}
        {rightElement && <InputRightElement>{rightElement}</InputRightElement>}
        {leftAddon && <InputLeftAddon>{leftAddon}</InputLeftAddon>}
        {rightAddon && <InputRightAddon>{rightAddon}</InputRightAddon>}
        {children}
      </InputGroup>
    ) : (
      input
    );

  if (props.type === "password" && showPasswordGenerator) {
    inner = (
      <HStack>
        {inner}
        <PassordGeneratorButton name={name} />
      </HStack>
    );
  }

  return (
    <FormControl
      isRequired={isRequired}
      isDisabled={isDisabled}
      id={id}
      name={name}
      label={label}
      helperText={helperText}
    >
      {inner}
    </FormControl>
  );
}
